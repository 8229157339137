@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
   body {
      @apply font-futura;
   }
   li {
      @apply px-4;
      @apply cursor-pointer;
   }
}

@font-face {
   font-family: 'Futura';
   src:
      local('Futura'),
      url('./fonts/FuturaPTBook.ttf') format('truetype');
}
